<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-card-body>
            <b-row>
              <b-col cols="12" xl="9" md="9">
                <h2>Pedidos</h2>
              </b-col>
            </b-row>
            <data-table :data="data" :columns="columns" @on-table-props-changed="reloadTable">
              <tbody slot="body" slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }">
                <tr v-for="item in data" :key="item.id">
                  <td v-for="column in columns" :key="column.name">
                    <data-table-cell :value="item" :name="column.name" :meta="column.meta" :comp="column.component"
                      :classes="column.classes" />
                    <slot v-if="column.label === 'Ações'">
                      <b-button variant="outline-primary" size="sm" class="btn-icon rounded-circle"
                        @click="Detalhes(item)">
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'Status'">
                      <b-badge :variant="NomeStatus(item).cor">
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      url: 'orders/getall',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: '#',
          name: 'id',
          orderable: false,
        },
        {
          label: 'Nome',
          name: 'customer.name',
          orderable: false,
        },
        {
          label: 'Status',
          orderable: false,
        },
        {
          label: 'Total Pedido',
          name: 'totalOrder',
          orderable: false,
        },
        {
          label: 'Ações',
          filterable: false,
        },
      ],
    }
  },
  created() {
    this.BuscarDados(this.url)
    this.$loading(true)
  },
  methods: {
    Detalhes(item) {
      if (item.statusId === 1) {
        localStorage.setItem('idPedido', JSON.stringify(item.id))
        this.$router.push({ name: 'app-pdv' })
      } else {
        this.$router.push({
          name: 'pedido-pago',
          params: { id: item.id },
        })
      }
    },
    NomeStatus(item) {
      if (item.statusId === 1) {
        return { cor: 'light-secondary', msg: 'Em Aberto' }
      }
      if (item.statusId === 2) {
        return { cor: 'light-success', msg: 'Aprovado' }
      }
      if (item.statusId === 3) {
        return { cor: 'light-primary', msg: 'Preparando' }
      }
      if (item.statusId === 4) {
        return { cor: 'success', msg: 'Faturado' }
      }
      if (item.statusId === 5) {
        return { cor: 'outline-primary', msg: 'Pronto' }
      }
      if (item.statusId === 6) {
        return { cor: 'primary', msg: 'Enviado' }
      }
      if (item.statusId === 7) {
        return { cor: 'outline-success', msg: 'Entregue' }
      }
      if (item.statusId === 8) {
        return { cor: 'light-warning', msg: 'Não entregue' }
      }
      if (item.statusId === 9) {
        return { cor: 'danger', msg: 'Cancelado' }
      }
      if (item.statusId === 11) {
        return { cor: 'outline-warning', msg: 'Fiado' }
      }
      if (item.statusId === 12) {
        return { cor: 'success', msg: 'Fiado Pago' }
      } return { cor: 'danger', msg: 'Sem Status' }
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      const { data } = await this.$http.get(url, { params: options })
      this.data = data
      this.$loading(false)
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
  },
}
</script>
